import React from 'react';
import { Blank } from 'grommet-icons';

const IconBrush = (props) => (
  <Blank
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 55"
    {...props}
    style={{ strokeWidth: 0 }}
  >
    <path d="M50.1,46,33.05,29c-.72-.73-3.36-7.19-4.86-11.1l-7-7h0L21,10.62l-.17-.16h0L12,1.72l-9,15,9.16,9,4,4.11.11.05c3.93,1.47,10.43,4.08,11.15,4.8l17,17.05a3.68,3.68,0,0,0,2.63,1.08h.11A4.1,4.1,0,0,0,50,51.55,3.9,3.9,0,0,0,50.1,46ZM12.24,3.49,20,11.24,18,13.34,12.67,24.57l-8.24-8Zm6.59,10.56,2-2L26.9,18.1,16.48,28.52l-3-3.11ZM49.2,50.77a2.92,2.92,0,0,1-2,.88,2.55,2.55,0,0,1-1.93-.76l-17-17c-1-1-8-3.71-10.74-4.75l10-10c1.05,2.72,3.83,9.71,4.81,10.69l17,17.05A2.8,2.8,0,0,1,49.2,50.77Z" />
    <path d="M44.73,46.26a1.85,1.85,0,0,0-.54,1.32,1.89,1.89,0,0,0,.54,1.33,1.91,1.91,0,0,0,1.33.55,1.88,1.88,0,0,0,1.87-1.88,1.82,1.82,0,0,0-.55-1.32A1.88,1.88,0,0,0,44.73,46.26Zm1.88,1.87a.78.78,0,0,1-1.1,0,.8.8,0,0,1-.22-.55.77.77,0,0,1,.22-.54.77.77,0,0,1,1.1,0h0a.77.77,0,0,1,.22.54A.8.8,0,0,1,46.61,48.13Z" />
  </Blank>
);

export default IconBrush;
